import { API_URL } from '../environment'
import { useAuth } from '../stores/auth'

interface FetcherRequestInit extends Omit<RequestInit, 'body'> {
  body?: string | object
}

export const CreateApiUrl = (path: string) => {
  return `${API_URL}${path}`
}

export type FetcherConfiguration = {
  stringify: boolean
  contentType: string | null
}

export const useFetcher = (config: Partial<FetcherConfiguration> = {}) => {
  const _configuration: FetcherConfiguration = {
    stringify: true,
    contentType: 'application/json',

    ...(config ?? {}),
  }

  let _fixedHeaders: { [key: string]: string } = {
    'Content-Type': _configuration.contentType || '',
  }

  const GetHeaders = (extraHeaders: { [key: string]: string }) => {
    const auth = useAuth()
    const headers = { ..._fixedHeaders, ...extraHeaders }

    if (auth.Token) {
      // -- auth token added to headers
      headers['Authorization'] = `Bearer ${auth.Token}`
    }

    if (!headers['Content-Type']) {
      delete headers['Content-Type']
    }
    return headers
  }

  const AddHeaders = (headers: { [key: string]: string }) => {
    _fixedHeaders = { ..._fixedHeaders, ...headers }
  }

  const ClearHeaders = () => {
    _fixedHeaders = {
      'Content-Type': _configuration.contentType || '',
    }
  }

  async function Fetch<T>(path: string, options?: FetcherRequestInit) {
    const headers = GetHeaders((options?.headers as { [key: string]: string }) ?? {})
    let body
    if (_configuration.stringify) {
      body = options?.body && typeof options.body !== 'string' ? JSON.stringify(options.body) : undefined
    } else {
      body = options?.body ?? undefined
    }

    const auth = useAuth()
    let triedRefresh = false // Estado para saber si ya intentamos el refresh
    try {
      //Primera solicitud
      let response = await fetch(CreateApiUrl(path), {
        ...options,
        headers,
        body: body as BodyInit,
      })

      // Verificar si el token ha expirado
      if (response.status === 401 && auth.refreshToken) {
        if (!triedRefresh) {
          // Solo intentamos refrescar una vez
          triedRefresh = true
          const refreshed = await auth.refreshToken()
          if (refreshed) {
            // Actualiza los headers con el nuevo token y reintenta la petición
            const newHeaders = GetHeaders((options?.headers as { [key: string]: string }) ?? {})
            response = await fetch(CreateApiUrl(path), {
              ...options,
              headers: newHeaders,
              body: body as BodyInit,
            })
          } else {
            throw new Error('No se pudo renovar el token. Redirigiendo a login.')
          }
        } else {
          throw new Error('Token inválido tras intento de renovación. Redirigiendo a login.')
        }
      }

      // Si el código de estado es 204 (No Content) o 201 (Created) con un cuerpo vacío
      if (response.status >= 200 && response.status <= 204 && response.headers.get('content-length') === '0') {
        return { data: null, error: null }
      }

      if (response.status >= 400) {
        throw await response?.json()
      }

      return { data: (await response.json()) as T, error: null }
    } catch (e) {
      return { data: null, error: e! as Error }
    }
  }

  return {
    Fetch,
    AddHeaders,
    ClearHeaders,
  }
}
