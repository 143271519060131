import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'products',
    path: 'products',
    redirect: { name: 'products-main' },
    meta: {
      title: 'Productos',
    },
    children: [
      {
        meta: {
          title: 'Productos',
        },
        name: 'products-main',
        path: '',
        component: () => import('../../../pages/shops/products/ProductsPage.vue'),
      },
    ],
  },
] as RouteRecordRaw[]
