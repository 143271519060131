import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'warehouse-creation',
    path: 'warehouse/create',
    component: () => import('../../../pages/shops/WareHouse/WareHouseCreation.vue'),
    meta: {
      title: 'Crear Almacèn',
      vanish_state: true,
    },
  },
] as RouteRecordRaw[]
