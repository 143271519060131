import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'agencies',
    path: 'agencies',
    component: () => import('../../components/TabsView.vue'),
    redirect: { name: 'agencies-main' },
    props: {
      title: '',
      routes: [],
    },
    meta: {
      title: 'Agencias',
    },
    children: [
      {
        meta: {
          title: 'Agencias',
        },
        name: 'agencies-main',
        redirect: { name: 'agency-general-info' },
        path: '',
        component: () => import('../../pages/agencies/AgencyList.vue'),
        children: [
          {
            path: 'info',
            name: 'agency-general-info',
            component: () => import('../../components/Agency/GeneralInfo.vue'),
          },
          {
            path: 'personal',
            name: 'agency-personal-department',
            component: () => import('../../components/CommingSoon.vue'),
            meta: {
              vanish_state: true,
            },
          },
          {
            path: 'settings',
            name: 'agency-settings',
            component: () => import('../../components/CommingSoon.vue'),
            meta: {
              vanish_state: true,
            },
          },
        ],
      },
      {
        meta: {
          title: 'Editar Agencia',
        },
        name: 'agency-detail',
        path: ':id',
        component: () => import('../../pages/agencies/Details.vue'),
        //New
        props: (route) => ({
          isNew: false,
          elementId: route.params.id,
          key: route.params.id, // esto fuerza la recreación del componente
        }),
      },
      {
        meta: {
          title: 'Crear Token',
        },
        name: 'agency-token-creation',
        path: 'token-creation',
        component: () => import('../../pages/agencies/CreateInvitationToken.vue'),
        //New
        props: {
          isNew: true,
          key: 'new', // clave única para la creación
        },
      },
    ],
  },
] as RouteRecordRaw[]
