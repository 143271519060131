import { RouteRecordRaw } from 'vue-router'
import { Claims } from '../../utils/enumsHandler'

export default [
  {
    path: 'access',
    name: 'access',
    redirect: { name: 'roles' },
    meta: {
      title: 'Acceso',
    },
    component: () => import('../../components/TabsView.vue'),
    props: {
      title: 'Acceso',
      routes: [
        { name: 'roles', label: 'Roles' },
        { name: 'claims', label: 'Permisos' },
      ],
    },
    children: [
      {
        name: 'claims',
        path: 'claims',
        meta: {
          title: 'Permisos',
        },
        component: () => import('../../pages/access/Claims.vue'),
      },
      {
        name: 'roles',
        path: 'roles',
        meta: {
          title: 'Roles',
          requiredClaims: [Claims.ReadRoles],
        },
        component: () => import('../../pages/access/Roles.vue'),
      },
    ],
  },
  {
    name: 'new-role',
    path: 'access/roles/new-role',
    component: () => import('../../pages/access/CreateOrModifyRole.vue'),
    meta: {
      title: 'Nuevo Role',
      requiredClaims: [Claims.WriteRoles],
    },
  },
  {
    name: 'role-detail',
    path: 'access/roles/:id',
    component: () => import('../../pages/access/CreateOrModifyRole.vue'),
    meta: {
      title: 'Editar Role',
      requiredClaims: [Claims.WriteRoles],
    },
  },
] as RouteRecordRaw[]
