// USAR SOLO PARA ENUMS QUE NO USEN STRINGS COMO VALORES

export function convertEnumToObject(_enum: object) {
  const solutionObject: { [key: string]: string } = {}

  Object.keys(_enum).forEach((key, index, listed) => {
    if (index < listed.length / 2) {
      solutionObject[key] = listed[Math.floor(listed.length / 2) + index]
    }
  })
  return solutionObject
}

export function convertEnumToSelectList(_enum: object) {
  const enumAsObj = convertEnumToObject(_enum)
  return Object.keys(enumAsObj).map((key: string) => {
    return { label: enumAsObj[key], name: key }
  })
}

export enum Claims {
  RegularAccess = 'RegularAccess',
  PurchaseAllowed = 'PurchaseAllowed',
  SuperAccess = 'SuperAccess',
  PanelAccess = 'PanelAccess',

  // Identity
  WriteRoles = 'WriteRoles',
  ReadRoles = 'ReadRoles',
  ReadClaims = 'ReadClaims',
  WriteClaims = 'WriteClaims',
  ReadUsers = 'ReadUsers',
  WriteUsers = 'WriteUsers',
  BanEmails = 'BanEmails',
  ReadAgency = 'ReadAgency',
  WriteAgency = 'WriteAgency',

  // Shops
  WritePromotion = 'WritePromotion',
  WriteCategory = 'WriteCategory',
  WriteCupon = 'WriteCupon',
  WriteProduct = 'WriteProduct',
  WriteShop = 'WriteShop',
  ReadShop = 'ReadShop',
  WriteProvider = 'WriteProvider',
  ReadOrders = 'ReadOrders',
  ReadShopSettings = 'ReadShopSettings',
  UpdateShopSettings = 'UpdateShopSettings',
  ReadWarehouse = 'ReadWarehouse',
  WriteWarehouse = 'WriteWarehouse',
  WriteProductInWarehouse = 'WriteProductInWarehouse',
  DeleteProductInWarehouse = 'DeleteProductInWarehouse',
  SendEmailToWarehouseStuff = 'SendEmailToWarehouseStuff',
  WriteProductAttribute = 'WriteProductAttribute',
  //news
  ReadPromotion = 'ReadPromotion',
  ReadCategory = 'ReadCategory',
  ReadCupon = 'ReadCupon',
  ReadProduct = 'ReadProduct',
  ReadProvider = 'ReadProvider',

  // RealEstate
  ReadAppointmentAgreement = 'ReadAppointmentAgreement',
  WriteAppointmentAgreement = 'WriteAppointmentAgreement',
  WriteAppointmentSlot = 'WriteAppointmentSlot',
  ReadLocation = 'ReadLocation',
  WriteLocation = 'WriteLocation',
  WriteProperty = 'WriteProperty',
  WriteRentOffer = 'WriteRentOffer',
  WriteSaleOffer = 'WriteSaleOffer',
  WriteRealEstateService = 'WriteRealEstateService',
  WriteTag = 'WriteTag',
  RealEstateWritePromotion = 'RealEstateWritePromotion',
  ReadRentOffer = 'ReadRentOffer',
  WriteRealEstateSettings = 'WriteRealEstateSettings',
  ReadRentAgreement = 'ReadRentAgreement',
  WriteRentAgreement = 'WriteRentAgreement',
  WriteAdvertiser = 'WriteAdvertiser',
  WriteConglomerate = 'WriteConglomerate',
  //news
  ReadConglomerate = 'ReadConglomerate',
  ReadProperties = 'ReadProperties',
  ReadSaleOffer = 'ReadSaleOffer',
  ReadRealEstateService = 'ReadRealEstateService',
  ReadProperty = 'ReadProperty',
  ReadAdvertiser = 'ReadAdvertiser',
  ReadTag = 'ReadTag',
  ReadRealEstateSettings = 'ReadRealEstateSettings',

  //GeneralPromotion
  ReadGeneralPromotion = 'ReadGeneralPromotion',
  WriteGeneralPromotion = 'WriteGeneralPromotion',

  //GeneralService
  ReadGeneralService = 'ReadGeneralService',
  WriteGeneralServices = 'WriteGeneralServices',

  //Notifications
  SendPrivatePush = 'SendPrivatePush',

  // Payment
  ReadPaymentOperations = 'ReadPaymentOperations',
  ReadPaymentBalanceOperations = 'ReadPaymentBalanceOperations',
  ReadPaymentRefundHistory = 'ReadPaymentRefundHistory',
  RefundPayment = 'RefundPayment',
  WriteMerchant = 'WriteMerchant',
  ReadMerchant = 'ReadMerchant',
  ReadMerchantOperation = 'ReadMerchantOperation',
  CompleteCashPayment = 'CompleteCashPayment',

  // CRM
  ReadTicket = 'ReadTicket',
  UpdateTicket = 'UpdateTicket',
  ReadSupportUser = 'ReadSupportUser',
  WriteSupportUser = 'WriteSupportUser',
  UpdateSupportUser = 'UpdateSupportUser',
  CRMWriteConfig = 'CRMWriteConfig',
  CRMReadConfig = 'CRMReadConfig',
}

export interface RequieredClaims {
  read?: Claims
  write?: Claims
}
