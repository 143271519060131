import { RouteRecordRaw } from 'vue-router'
import { Claims } from '../../utils/enumsHandler'

export default [
  {
    name: 'promotions',
    path: 'promotions',
    component: () => import('../../components/TabsView.vue'),
    redirect: { name: 'promotions-main' },
    props: {
      title: 'Promociones',
      routes: [{ name: 'promotions-main', label: 'Listado' }],
    },
    meta: {
      title: 'Promociones',
    },
    children: [
      {
        meta: {
          title: 'Promociones',
          requiredClaims: [Claims.ReadGeneralPromotion],
        },
        name: 'promotions-main',
        path: '',
        component: () => import('../../pages/promotions/promotion-list/PromotionList.vue'),
      },
      {
        meta: {
          title: 'Editar Promocion',
          requiredClaims: [Claims.WriteGeneralPromotion],
        },
        name: 'promotion-detail',
        path: ':id',
        component: () => import('../../pages/promotions/promotion-edit/promotion-edit.vue'),
      },
      {
        meta: {
          title: 'Crear Promocion',
          requiredClaims: [Claims.WriteGeneralPromotion],
        },
        name: 'promotion-creation',
        path: 'creation/:id',
        component: () => import('../../pages/promotions/promotion-creation/PromotionCreate.vue'),
      },
    ],
  },
] as RouteRecordRaw[]
