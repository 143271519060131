import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'shops-tags',
    path: 'shops-tags',
    redirect: { name: 'shops-tags-main' },
    meta: {
      title: 'Tags de Tiendas',
    },
    children: [
      {
        meta: {
          title: 'Tags de Tiendas',
        },
        name: 'shops-tags-main',
        path: '',
        component: () => import('../../../pages/shops/tags/ShopsTagsPage.vue'),
      },
      {
        meta: {
          title: 'Editar Tag de Tiendas',
        },
        name: 'shops-tag-detail',
        path: ':id',
        component: () => import('../../../pages/shops/tags/CreateOrModify.vue'),
        props: (route) => ({
          ownProps: {
            isNew: false,
          },
          elementId: route.params.id,
        }),
      },
      {
        meta: {
          title: 'Crear Tags de Tiendas',
        },
        name: 'shops-tag-creation',
        path: 'shops-tag-creation',
        component: () => import('../../../pages/shops/tags/CreateOrModify.vue'),
        props: {
          ownProps: {
            isNew: true,
          },
          key: 'new-shops-tag',
        },
      },
    ],
  },
] as RouteRecordRaw[]
