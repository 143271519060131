import { RouteRecordRaw } from 'vue-router'
import warehouses from './warehouses'
import products from './products'

export default [
  {
    name: 'shops',
    path: 'shops',
    component: () => import('../../../components/TabsView.vue'),
    redirect: { name: 'shops-main' },
    props: {
      title: 'Tiendas',
      routes: [],
    },
    meta: {
      title: 'Tiendas',
    },
    children: [
      {
        meta: {
          title: 'Tiendas',
        },
        name: 'shops-main',
        path: '',
        component: () => import('../../../pages/shops/ShopList.vue'),
      },
      {
        meta: {
          title: 'Editar Tienda',
        },
        name: 'shop-detail',
        path: ':id',
        component: () => import('../../../pages/shops/Details.vue'),
        //New
        props: (route) => ({
          //isNew: false,
          elementId: route.params.id,
          //key: route.params.id, // esto fuerza la recreación del componente
        }),
      },
      {
        meta: {
          title: 'Crear Tienda',
        },
        name: 'shop-creation',
        path: 'shop-creation',
        component: () => import('../../../pages/shops/CreateOrModify.vue'),
        //New
        props: {
          ownProps: {
            isNew: true,
          },
          key: 'new', // clave única para la creación
        },
      },
      ...warehouses,
      ...products,
    ],
  },
] as RouteRecordRaw[]
