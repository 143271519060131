import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useFetcher } from '../utils/fetch'
import { useToast } from 'vuestic-ui'

interface AuthBody {
  email: string
  password: string
}

interface LoginResponse {
  access_token: string
  refresh_token: string
  payload: {
    sub: string
    email: string
    firstName: string
    lastName: string
    claims: string[]
    roles: string[]
  }
}

interface RefreshResponse {
  accessToken: string
  refreshToken: string
}

const AUTH_KEY_LOCALSTORAGE = 'authToken'
const USER_CLAIMS = 'userClaims'
const AUTH_REFRESH_KEY_LOCALSTORAGE = 'refreshToken'
const USER_LOCALSTORAGE = 'user'

export const useAuth = defineStore('auth', () => {
  // const IsLogued = ref<boolean>(false)s
  const Token = ref<string | null>(null)
  const Refresh = ref<string | null>(null)
  const sub = ref<string | null>(null)
  const Claims = ref<string[]>([])

  const IsLogued = async () => {
    const { error } = await useFetcher().Fetch('identity/auth/verify_jwt', {
      method: 'POST',
    })
    if (error) {
      return false
    }
    return true
  }

  const Login = async (authBody: AuthBody) => {
    const { error, data } = await useFetcher().Fetch<LoginResponse>('identity/auth/panel_signin', {
      method: 'POST',
      body: authBody,
    })

    const { init: notify } = useToast()
    if (error) {
      notify({
        title: 'Error',
        message: error?.message ?? 'unknown error',
      })
      return false
    } else if (data) {
      // IsLogued.value = true
      Token.value = data.access_token
      Refresh.value = data.refresh_token
      sub.value = data.payload.sub
      Claims.value = data.payload.claims
      localStorage.setItem(AUTH_KEY_LOCALSTORAGE, data.access_token)
      localStorage.setItem(AUTH_REFRESH_KEY_LOCALSTORAGE, data.refresh_token)
      localStorage.setItem(USER_LOCALSTORAGE, data.payload.sub)
      localStorage.setItem(USER_CLAIMS, JSON.stringify(data.payload.claims))
      return true
    }
  }

  const refreshToken = async () => {
    const refresh_token = localStorage.getItem(AUTH_REFRESH_KEY_LOCALSTORAGE)
    const user = localStorage.getItem(USER_LOCALSTORAGE)
    if (!refresh_token || !user) {
      Logout() // Cerrar sesión si no hay un token de refresh válido
      return false
    }

    const { error, data } = await useFetcher().Fetch<RefreshResponse>('identity/auth/refresh_token', {
      method: 'POST',
      headers: {
        refresh_token: refresh_token,
      },
      body: { userId: user, isFromPanel: true },
    })

    if (error) {
      Logout() // Cerrar sesión si el refresco falla
      return false
    } else if (data) {
      Token.value = data.accessToken
      Refresh.value = data.refreshToken
      localStorage.setItem(AUTH_KEY_LOCALSTORAGE, data.accessToken)
      localStorage.setItem(AUTH_REFRESH_KEY_LOCALSTORAGE, data.refreshToken)
      return true
    }
  }

  const Logout = () => {
    // IsLogued.value = false
    Token.value = null
    Refresh.value = null
    sub.value = null
    localStorage.removeItem(AUTH_KEY_LOCALSTORAGE)
    localStorage.removeItem(AUTH_REFRESH_KEY_LOCALSTORAGE)
    localStorage.removeItem(USER_LOCALSTORAGE)
    localStorage.removeItem(USER_CLAIMS)
  }

  const loadData = () => {
    Token.value = localStorage.getItem(AUTH_KEY_LOCALSTORAGE)
    // const authToken = localStorage.getItem(AUTH_KEY_LOCALSTORAGE)
    //   if (authToken) {
    //     Token.value = authToken
    //   }
    const storedClaims = localStorage.getItem(USER_CLAIMS)
    if (storedClaims) {
      Claims.value = JSON.parse(storedClaims)
    }
    // if (Token.value) {
    //   // TODO: see if is a valid token
    //   // IsLogued.value = true
    // }
  }

  loadData()

  return {
    Login,
    refreshToken,
    Logout,
    IsLogued,
    Token,
    sub,
    Claims,
  }
})
