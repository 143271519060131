import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'categories',
    path: 'categories',
    // component: () => import('../../../components/TabsView.vue'),
    redirect: { name: 'categories-main' },
    // props: {
    //   title: 'Categorias',
    //   routes: [],
    // },
    meta: {
      title: 'Categorias',
    },
    children: [
      {
        meta: {
          title: 'Categorias',
        },
        name: 'categories-main',
        path: '',
        component: () => import('../../../pages/shops/categories/CategoriesPage.vue'),
      },
      {
        meta: {
          title: 'Editar Categoria',
        },
        name: 'category-detail',
        path: ':id',
        component: () => import('../../../pages/shops/categories/CreateOrModify.vue'),
        //New
        props: (route) => ({
          //isNew: false,
          ownProps: {
            isNew: false,
          },
          elementId: route.params.id,
          //key: route.params.id, // esto fuerza la recreación del componente
        }),
      },
      {
        meta: {
          title: 'Crear Categoria',
        },
        name: 'category-creation',
        path: 'category-creation',
        component: () => import('../../../pages/shops/categories/CreateOrModify.vue'),
        //New
        props: {
          ownProps: {
            isNew: true,
          },
          key: 'new', // clave única para la creación
        },
      },
    ],
  },
] as RouteRecordRaw[]
