import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

// import RouteViewComponent from '../layouts/RouterBypass.vue'
import { useGlobalStore } from '../stores/global-store'
import { useAuth } from '../stores/auth'

import realestateRoutes from './modules/realestate'
import accessRoutes from './modules/access'
import usersRoutes from './modules/users'
import paymentsRoutes from './modules/payments'
import promotionsRoutes from './modules/promotions'
import servicesRoutes from './modules/general-services'
import agenciesRoutes from './modules/agencies'
import publicRoutes from './modules/public'
import shopsRoutes from './modules/shops/shops'
import categoriesRoutes from './modules/shops/categories'
import providersRoutes from './modules/shops/providers'
import shopPromotionsRoutes from './modules/shops/promotions'
import cuponRoutes from './modules/shops/cupons'
import shopsTagRoutes from './modules/shops/tags'
import shopsCategoriesRoutes from './modules/shops/shopsCategories'

import { Claims } from '../utils/enumsHandler'
import atributes from './modules/atributes'

declare module 'vue-router' {
  interface RouteMeta {
    requiredClaims?: string[] // Agregar propiedad personalizada
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    meta: {
      requireAuth: true,
      requiredClaims: [],
    },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
        meta: {
          title: 'Dashboard',
        },
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
        meta: {
          title: 'Configuración',
        },
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
        meta: {
          title: 'Preferencias',
        },
      },

      ...usersRoutes,
      ...accessRoutes,
      ...realestateRoutes,
      ...paymentsRoutes,
      ...promotionsRoutes,
      ...servicesRoutes,
      ...agenciesRoutes,
      ...publicRoutes,
      ...shopsRoutes,
      ...categoriesRoutes,
      ...providersRoutes,
      ...shopPromotionsRoutes,
      ...cuponRoutes,
      ...atributes,
      ...shopsTagRoutes,
      ...shopsCategoriesRoutes,
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
        meta: {
          saveAsTab: false,
        },
      },
      {
        name: 'logout',
        path: 'logout',
        redirect() {
          useAuth().Logout()
          return { name: 'login' }
        },
        meta: {
          saveAsTab: false,
        },
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
        meta: {
          saveAsTab: false,
        },
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
        meta: {
          saveAsTab: false,
        },
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
        meta: {
          saveAsTab: false,
        },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
    meta: {
      saveAsTab: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

async function hasPermission(route: RouteLocationNormalized) {
  const authStore = useAuth()
  const isLogued = await authStore.IsLogued()

  if (!route.meta.requireAuth) {
    return true
  }

  if (!isLogued) {
    return false
  }

  const userClaims = authStore.Claims

  if (userClaims.includes(Claims.SuperAccess)) {
    return true
  }

  if (route.meta.requiredClaims) {
    return route.meta.requiredClaims.every((claim: string) => userClaims.includes(claim))
  }

  return true
  // return !route.meta.requireAuth || (route.meta.requireAuth && isLogued)
}

router.beforeEach(async (to, from, next) => {
  // Si la ruta no requiere autenticación, simplemente pasa al siguiente middleware
  if (!to.meta.requireAuth) {
    if (!to.meta.vanish_state) {
      useGlobalStore().addPathToTabs({
        path: to.path,
        name: (to.meta?.title as string) || 'unknown',
        saveAsTab: (to.meta?.saveAsTab as boolean) ?? true,
      })
    }
    return next()
  }

  if (await hasPermission(to)) {
    if (!to.meta.vanish_state) {
      useGlobalStore().addPathToTabs({
        path: to.path,
        name: (to.meta?.title as string) || 'unknown',
        saveAsTab: (to.meta?.saveAsTab as boolean) ?? true,
      })
    }
    next()
  } else {
    next({ name: 'login', query: { redirectTo: to.path } })
  }
})
router.afterEach(() => {
  useGlobalStore().toggleLoading()
})

export default router
