import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'providers',
    path: 'providers',
    redirect: { name: 'providers-main' },
    meta: {
      title: 'Proveedores',
    },
    children: [
      {
        meta: {
          title: 'Proveedores',
        },
        name: 'providers-main',
        path: '',
        component: () => import('../../../pages/shops/providers/ProvidersPage.vue'),
      },
      {
        meta: {
          title: 'Editar Proveedor',
        },
        name: 'provider-detail',
        path: ':id',
        component: () => import('../../../pages/shops/providers/CreateOrModify.vue'),
        //New
        props: (route) => ({
          //isNew: false,
          ownProps: {
            isNew: false,
          },
          elementId: route.params.id,
          //key: route.params.id, // esto fuerza la recreación del componente
        }),
      },
      {
        meta: {
          title: 'Crear Proveedor',
        },
        name: 'provider-creation',
        path: 'provider-creation',
        component: () => import('../../../pages/shops/providers/CreateOrModify.vue'),
        //New
        props: {
          ownProps: {
            isNew: true,
          },
          key: 'new', // clave única para la creación
        },
      },
    ],
  },
] as RouteRecordRaw[]
