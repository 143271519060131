import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'public',
    path: 'public',
    component: () => import('../../components/TabsView.vue'),
    // component: () => import('../../pages/agencies/CreateAgency.vue'),
    // redirect: { name: 'create-agency' },
    meta: {
      title: 'Public',
      requireAuth: false,
    },
    props: {
      title: '',
      routes: [],
    },
    children: [
      {
        meta: {
          title: 'Crear Agencia',
          requireAuth: false,
        },
        name: 'create-agency',
        path: 'create-agency',
        component: () => import('../../pages/agencies/CreateAgency.vue'),
      },
    ],
  },
] as RouteRecordRaw[]
