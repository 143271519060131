import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'shops-categories',
    path: 'shops-categories',
    redirect: { name: 'shops-categories-main' },
    meta: {
      title: 'Categorias de Tiendas',
    },
    children: [
      {
        meta: {
          title: 'Categorias de Tiendas',
        },
        name: 'shops-categories-main',
        path: '',
        component: () => import('../../../pages/shops/shops-categories/ShopsCategoriesPage.vue'),
      },
      {
        meta: {
          title: 'Editar Categoria de Tiendas',
        },
        name: 'shops-category-detail',
        path: ':id',
        component: () => import('../../../pages/shops/shops-categories/CreateOrModify.vue'),
        props: (route) => ({
          ownProps: {
            isNew: false,
          },
          elementId: route.params.id,
        }),
      },
      {
        meta: {
          title: 'Crear Categoria de Tiendas',
        },
        name: 'shops-category-creation',
        path: 'shops-category-creation',
        component: () => import('../../../pages/shops/shops-categories/CreateOrModify.vue'),
        props: {
          ownProps: {
            isNew: true,
          },
          key: 'new-shops-category',
        },
      },
    ],
  },
] as RouteRecordRaw[]
